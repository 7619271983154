<template>
  <v-container>
    <step-buttons-therapy
      class="my-3"
      :disabled-next="!searchedPatient"
      @click-next="submit"
      @click-back="backToList"
    />
    <therapy-card title="Dados do paciente">
      <v-form ref="form">
        <v-row dense>
          <v-col lg="4" sm="5" cols="9">
            <v-text-field
              ref="inputEmail"
              v-model="patient.email"
              filled
              label="E-mail *"
              type="email"
              :rules="[$rules.required]"
              @keydown.native.enter="checkExistingPatient"
            />
          </v-col>
          <v-col sm="2" cols="3">
            <v-btn
              class="ml-2"
              fab
              color="primary"
              @click="checkExistingPatient"
            >
              <v-icon>check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div v-show="searchedPatient">
          <v-row dense>
            <v-col lg="4" sm="6" cols="12">
              <v-text-field
                v-model="patient.name"
                filled
                label="Nome completo *"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="6" cols="12">
              <v-text-field
                v-model="patient.document"
                filled
                :rules="[$rules.required]"
                label="Documento pessoal *"
              />
            </v-col>
            <v-col lg="3" sm="4" cols="12">
              <v-text-field
                v-model="patient.occupation"
                filled
                label="Ocupação"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="4" cols="12">
              <v-select
                v-model="patient.maritalStatusId"
                filled
                :items="maritalStatus"
                item-text="name"
                item-value="id"
                label="Estado civil"
                return-object
              ></v-select>
            </v-col>
            <v-col lg="3" sm="4" cols="12">
              <input-date
                v-model="patient.birthDate"
                filled
                label="Data de nascimento *"
                :rules="[$rules.date]"
              ></input-date>
            </v-col>
            <v-col lg="3" sm="6" cols="12">
              <v-text-field
                v-model="patient.phone"
                v-mask="['(##)####-####', '(##)#####-####']"
                filled
                label="Telefone pessoal *"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="6" cols="12">
              <v-text-field
                v-model="patient.emergencyPhone"
                v-mask="['(##)####-####', '(##)#####-####']"
                filled
                label="Tel. de emergência"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="4" cols="12">
              <v-text-field
                v-model="patient.zipCode"
                filled
                :loading="isLoading"
                label="CEP"
                maxlength="8"
              />
            </v-col>
            <v-col lg="4" sm="8" cols="12">
              <v-text-field
                ref="inputStreet"
                v-model="patient.street"
                filled
                :loading="isLoading"
                label="Endereço completo (incluindo número)"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="4" cols="12">
              <v-text-field
                v-model="patient.neighborhood"
                filled
                :loading="isLoading"
                label="Bairro"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="4" cols="12">
              <v-text-field
                v-model="patient.city"
                filled
                :loading="isLoading"
                label="Cidade *"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="6" cols="12">
              <v-text-field
                v-model="patient.state"
                filled
                :loading="isLoading"
                label="UF (Estado) *"
                maxlength="2"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col lg="3" sm="6" cols="12">
              <v-text-field
                v-model="patient.country"
                filled
                :loading="isLoading"
                label="País *"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </therapy-card>
    <step-buttons-therapy
      class="my-3"
      :disabled-next="!searchedPatient"
      @click-next="submit"
      @click-back="backToList"
    />
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { find } from "@/services/local/marital-status-service";
import { findUserToTherapy } from "@/services/external/users-service";
import cep from "cep-promise";

export default {
  directives: {
    mask,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    step: {
      type: [String, Number],
      default: () => {},
    },
  },
  data: () => ({
    patient: {},
    findUser: {},
    maritalStatus: [],
    searchedPatient: false,
  }),
  computed: {
    zipCode() {
      return this.patient.zipCode;
    },
    isLoading() {
      return this.$store.getters["loading/isActive"];
    },
  },
  watch: {
    zipCode(value) {
      if (value && value.length === 8 && !this.patient.street) {
        this.handleAddress(value);
      }
    },
  },
  mounted() {
    this.fillUser();
  },
  methods: {
    backToList() {
      this.$router.push({ name: "therapys" });
    },
    fillUser() {
      if (this.$store.getters["therapy/patient"].email) {
        this.patient = JSON.parse(
          JSON.stringify(this.$store.getters["therapy/patient"])
        );
        this.searchedPatient = true;
      }
    },
    submit() {
      if (!this.$refs.form.validate()) return;

      this.$store.commit("therapy/savePatient", this.patient);
      this.$emit("next");
    },
    async checkExistingPatient() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.inputEmail.validate()) return;
        if (navigator.onLine) {
          await this.findExistsPatient();
        } else {
          this.searchedPatient = true;
        }
        await this.findMaritalStatus();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async findExistsPatient() {
      try {
        const { email } = this.patient;
        const { data } = await findUserToTherapy({
          email,
        });
        this.searchedPatient = true;
        this.patient = { email: this.patient.email, ...data };
        this.$refs.form.resetValidation();
      } catch (error) {
        this.$errorHandler(error);
      }
    },

    async findMaritalStatus() {
      try {
        this.maritalStatus = await find();
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    async handleAddress(zipCode) {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await cep(zipCode);
        const { street, neighborhood, city, state } = response;
        this.$set(this.patient, "neighborhood", neighborhood);
        this.$set(this.patient, "street", street);
        this.$set(this.patient, "city", city);
        this.$set(this.patient, "state", state);
        this.$set(this.patient, "country", "Brasil");
        this.$refs.inputStreet.focus();
      } catch (error) {
        this.$toasted.global.info({
          message:
            "Endereço não encontrado, favor preencher os campos manualmente.",
        });
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
